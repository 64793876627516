<template>
  <div class="corporateInformation-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="企业信息" name="first">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="ruleForm"
        >
          <el-form-item label="企业LOGO" prop="logo">
            <!-- <el-upload
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-change="selectFile"
              :limit="1"
              :show-file-list="false"  
            >
              <div
                style="height: 100%; position: relative"
                class="d-flex flex-column justify-content-center"
              >
                <i slot="default" class="el-icon-plus"></i>
                <span style="line-height: initial">上传图片</span>
              </div>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="companyMsg.companyLogo"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload> -->
            
            <!-- <el-upload
              action="https://api.yiliit.com/file/uploadObjectOSS"
              list-type="picture-card"
              :limit="1"
              :on-progress="handProgress"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :file-list="fileList"
             >
              <div
                style="height: 100%; position: relative"
                class="d-flex flex-column justify-content-center"
              >
                <i slot="default" class="el-icon-plus"></i>
                <span style="line-height: initial">上传图片</span>
              </div>
              
            </el-upload> -->
            <el-upload :action="uploadUrl" 
                        list-type="picture-card" 
                        :class="{ disabled: uploadDisabled }"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess" 
                        :file-list="fileList" 
                        :limit="1">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">上传图片</div>
              </el-upload>
            
            <!-- <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog> -->
            <!-- <div class="">
              <img :src="companyMsg.companyLogo" class="w150 h150 br10" mode="aspectFill" alt="">
            </div> -->
            <!-- <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog> -->
          </el-form-item>
          <el-form-item label="企业名称" prop="name">
            <el-input
              v-model="companyMsg.companyName"
              style="width: 240px; margin-right: 8px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-select
              v-model="companyMsg.industryId"
              style="width: 240px"
              placeholder="请选择"
            >
              <el-option
                v-for="(item) in industryList"
                :key="item.industryId"
                :label="item.industryName"
                :value="item.industryId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="企业密令" prop="secretOrder">
            <el-input
              v-model="companyMsg.password"
              style="width: 240px; margin-right: 8px"
              placeholder="其他人加入企业需此密令"
            />
          </el-form-item>
          <el-form-item label="企业公告" prop="announcements">
            <el-input
              v-model="companyMsg.companyNotice"
              type="textarea"
              size="medium"
              style="width: 464px; font-size：16px"
              :rows="6"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="save"
              type="primary"
              @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {fetchCompany,fetchIndustryList,updCompany} from '@/api/companyManage.js'
export default {
  name: "corporateInformation",
  data() {
    return {
      activeName: "first",
      disabled: false,
      ruleForm: {
        companyLogo: "",
        companyName: "",
        industryId: "",
        password: "",
        announcements: "",
      },
      rules: {
        companyLogo: [{ required: true, message: "请上传logo" }],
        companyName: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        industryId: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入企业密令", trigger: "blur" },
        ],
      },
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      companyMsg:'', //公司信息
      industryList:'', //行业信息
      fileList:[]
    };
  },
  mounted(){
    this.getCompany()
    this.getIndustryList()
  },
  computed: {
    uploadDisabled(){
      return this.fileList.length !== 0;
    },
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = []
      this.ruleForm.photos = ''
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log('file',file)
      this.companyMsg.companyLogo = this.ossUrl +  file.response.data 
      console.log(this.companyMsg)
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    
    /**@method 提交表单 */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updCompany(this.companyMsg)
          .then(res=>{
            if(res.code == 200){
              this.$message({
                type:'success',
                message: "保存成功",
              })
            }
          })
          .catch(err=>{
            this.$message.error(err.message)
          })
        } else {
          this.$message({
            type:'success',
            message: "保存成功",
          })
          return false;
        }
      });
    },
    //获取公司信息
    getCompany(){
      fetchCompany()
      .then(res=>{
        this.companyMsg = res.data
        console.log(this.companyMsg)
        this.fileList = [{url:this.companyMsg.companyLogo,uid:1}],
        this.ruleForm.companyName = this.companyMsg.companyName
        this.ruleForm.industryName = this.companyMsg.industryName
        this.ruleForm.password = this.companyMsg.password
      })
    },
    //获取行业列表
    getIndustryList(){
      fetchIndustryList()
      .then(res=>{
        console.log(res)
        this.industryList = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    }
    
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
.corporateInformation-view {
  padding: 16px 24px;
}
/deep/.el-tabs__content{
  background: #ffffff;
}
.ruleForm {
  margin-top: 24px;
}
.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}
.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}
</style>